@import 'colors';

@keyframes animateBg {
  0% { background-position: 0% 0%; }
  100% { background-position: 0% 100%; }
}

@keyframes fadeIn {
  0% { opacity: 0.2; }
  100% { opacity: .8; }
}

body {
  margin: 0 0 0 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background: $color-bg-main;

  .App {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    animation: animateBg 10s linear alternate-reverse infinite;
    background-image: linear-gradient(0deg,$color-bg-main,$color-bg-sub);
    background-size: 100% 200%;

    .App-content {
      width: 100%;
      height: 100%;
      animation: fadeIn 1.5s ease;
    }

    .App-opener {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .title {
        color: $color-white;
        font-size: 5em;
        font-weight: 700;
      }

      img {
        height: 50vh;
        width: auto;
        transform: translateY();
        animation: fadeIn 2s ease alternate infinite;
      }
    }
  }

  .page {
    padding-top: 50px;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .spacer {
    flex: 1;
  }
}

* {
  box-sizing: border-box;
}

.firefly {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 0.4vw;
  height: 0.4vw;
  margin: -0.2vw 0 0 9.8vw;
  animation: ease 200s alternate infinite;
  pointer-events: none;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform-origin: -10vw;
  }
  
  &::before {
    background: black;
    opacity: 0.4;
    animation: drift ease alternate infinite;
  }
  
  &::after {
    background: white;
    opacity: 0;
    box-shadow: 0 0 0vw 0vw yellow;
    animation: drift ease alternate infinite, flash ease infinite;
  }
}
  
// Randomize Fireflies Motion
@for $i from 1 through 15 {
  
  $steps: random(12) + 16;
  $rotationSpeed: random(10) + 8s;
  
  .firefly:nth-child(#{$i}) {
    animation-name: move#{$i};

    &::before {
      animation-duration: #{$rotationSpeed};
    }

    &::after {
      animation-duration: #{$rotationSpeed}, random(6000) + 5000ms;
      animation-delay: 0ms, random(8000) + 500ms;
    }
  }

  @keyframes move#{$i} {
    @for $step from 0 through $steps {
      #{$step * (100 / $steps)}% {
        transform: translateX(random(100) - 50vw) translateY(random(100) - 50vh) scale(random(75) / 100 + .25);
      }
    }
  }
}

@keyframes drift {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes flash {
  0%, 30%, 100% {
    opacity: 0;
    box-shadow: 0 0 0vw 0vw yellow;
  }
  5% {
    opacity: 1;
    box-shadow: 0 0 2vw 0.4vw yellow;
  }
}

@media screen and (min-width: 960px) and (max-width: 1400px) {
  body {
    font-size: 14px;
  }
}


@media screen and (max-width: 960px) {
  body {
    font-size: 12px;
    .App {
      .App-opener {
        .title {
          color: $color-white;
          font-size: 3em;
          font-weight: 700;
        }
      }
    }
  }
  .page {
    padding-top: 60px;
    width: 100%;
    height: 100%;
    overflow: auto;
  }
}