@import '../../colors.scss';

.auth {
  display: flex;
  align-items: center;
  justify-content: center;

  .title {
    font-size: 4em;
    font-weight: 700;
    color: $color-white;
    margin-bottom: 10px;
  }

  .subtitle {
    font-size: 2em;
    font-weight: 700;
    color: $color-white;
    margin-bottom: 20px;
  }

  .return {
    border: none;
    box-shadow: 0 0 20px 0 rgba(0,0,0,.5);
    background: $color-white;
    color: $color-bg-sub;
    font-size: 2em;
    padding: .5em 1em;
    cursor: pointer;
    transition: all .2s;
    
    &:hover {
      padding: .4em .9em;
    }
  }
}